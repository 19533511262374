import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import { m } from 'framer-motion';
import styled from 'styled-components';
const Container = styled.nav `
  --color: ${brand.white};
  --gap: 4px;
  --height: 16px;
  --opacityInactive: 1;
  --opacityActive: 0.65;
  --tapSize: 48px;
  --width: 16px;

  --tapPadding: calc((var(--tapSize) - var(--width)) / 2);

  display: flex;
  flex-direction: row;
  gap: var(--gap);
  place-content: center;
  place-items: center;
  user-select: none;

  @media ${from(Device.Tablet)} {
    --gap: 8px;
    --height: 16px;
    --width: 16px;
  }
`;
const Item = styled.button `
  position: relative;
  display: flex;
  background: none;
  border: 0;
  color: var(--color);
  margin: 0;
  
  padding: var(--tapPadding);
  place-content: center;
  place-items: center;

  &::before {
    content: '';
    display: block;
    background: currentColor;
    border: 0;
    color: currentColor;
    height: var(--height);
    width: var(--width);
    transform: scale(1);
    transition: transform 0.15s ease-out;
    will-change: transform;
  }

  /* Enable focus styles for Safari */
  &:focus {
    background: none;
    outline: none;

    &::before {
      outline: auto;
      outline-color: currentColor;
      outline-offset: 5px;
      outline-width: thin;
    }
  }

  /* Enable hover styles for Safari */
  &:hover {
    background: none;
    &::before {
      transform: scale(0.6);
    }
  }

  /* Disable focus styles if focus visible is supported */
  &:not(:focus-visible):focus {
    outline: none;

    &::before {
      outline: none;
    }
  }

  /* Reapply focus styles for focus-visible browsers */
  &:focus-visible {
    outline: none;

    &::before {
      outline: auto;
      outline-color: currentColor;
      outline-offset: 5px;
      outline-width: thin;
    }
  }

  /* Apply hover styles for focus-visible browsers */
  &:not(:focus-visible):hover {
    &::before {
      transform: scale(0.6);
    }
  }

  /* Apply active styles */
  &[aria-current='true'] {
    &::before {
      transform: scale(0.6);
    }
  }
`;
const ActiveIndicator = styled(m.span) `
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border: 2px solid var(--color);
  height: 20px;
  width: 20px;
  transform: translate(-50%, -50%);
`;
const SvgIndicator = styled(m.svg) `
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
`;
const Circle = styled(m.circle) `
  fill: none;
  stroke: var(--color);
  stroke-width: 2px;
`;
const SvgBox = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  stroke: var(--color);
  border: 'none';
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
`;
const SvgOutline = styled(m.svg) `
  width: 100%;
  height: 100%;
  transform: scaleX(-1) rotate(90deg);
`;
const SvgPath = styled(m.path) `
`;
const DotPaginationStyles = {
    Container,
    Item,
    ActiveIndicator,
    SvgIndicator,
    Circle,
    SvgBox,
    SvgOutline,
    SvgPath,
};
export default DotPaginationStyles;
