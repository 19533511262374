import * as React from 'react';
import S from './DotPagination.styles';
import { withMotionMax } from '@hoc/withMotionMax';
const DotPagination = ({ autoplay = 0, count, paused = false, value, customLayoutId = 'indicator', onChange, onHover, }) => {
    return (React.createElement(S.Container, { "aria-label": "Slider Pagination" }, Array.from(Array(count)).map((_, index) => (React.createElement(S.Item, { key: `DotPaginationItem_${index}`, "aria-current": index === value, "aria-label": `Go to ${index + 1}`, onClick: (event) => {
            event.preventDefault();
            onChange(index);
        }, onMouseEnter: () => (onHover && autoplay > 0) ? onHover(true) : null, onMouseLeave: () => (onHover && autoplay > 0) ? onHover(false) : null }, index === value && renderActiveIndicator())))));
    function renderActiveIndicator() {
        const isActiveAnimating = autoplay > 0 && !paused;
        if (isActiveAnimating) {
            return (React.createElement(S.SvgBox, null,
                React.createElement(S.SvgOutline, { viewBox: "0 0 204 204", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement(S.SvgPath, { initial: { pathLength: isActiveAnimating ? 0 : 1 }, animate: { pathLength: 1 }, transition: {
                            duration: autoplay / 1000,
                            ease: "linear",
                        }, strokeWidth: 40, strokeDasharray: "0 1", stroke: "currentColor", fill: "none", d: "M2.7.7v200h200V.7H2.7Z" }))));
        }
        return (React.createElement(S.ActiveIndicator, { "aria-hidden": true, 
            // "layoutId" handles animating between positions automatically.
            layoutId: customLayoutId, transition: {
                duration: 0.3,
                ease: 'easeOut',
            } }));
    }
};
export default withMotionMax(DotPagination);
