import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { from, Device } from '@helpers/media';
import styled from 'styled-components';
const Wrapper = styled.div `
  margin: 25px 0px;
`;
const Inner = styled.ul `
  grid-column: span 12;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0px;
  height: 16px;
  white-space: nowrap;

  li {
    position: relative;
    white-space: nowrap;

    &:after {
      content: '|';
      padding: 0px 5px;
    }

    &:last-child:after {
      display: none;
    }

    a {
      color: ${brand.grey.grey20};
      ${fonts.DaxPro.Regular};
      font-size: 14px;

      @media ${from(Device.TabletLarge)} {
        font-size: 16px;
      }
    }
  }
`;
export const BreadcrumbsStyles = { Wrapper, Inner };
