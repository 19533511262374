import { HydrateOption } from '@core/enums';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import React from 'react';
import { BreadcrumbsStyles } from './Breadcrumbs.styles';
const Breadcrumbs = (props) => {
    if (!props.items || props.items.length === 0) {
        return null;
    }
    return (React.createElement(BreadcrumbsStyles.Wrapper, null,
        React.createElement(Grid.Default, null,
            React.createElement(BreadcrumbsStyles.Inner, null, props.items.map((x) => (React.createElement("li", { key: x.name },
                React.createElement("a", { href: x.url }, x.name))))))));
};
export default withWidget(Breadcrumbs, 'Breadcrumbs', { hydrate: HydrateOption.Never });
